import React from 'react';
import IconCloud from './icon_cloud';
import Meteors from './Meteors';

export default function Body() {
const iconSlugs = [
  'html5', 'css3', 'javascript', 'typescript', 'react', 'next.js', 'node.js', 
  'express', 'redux', 'graphql', 'apollo', 'webpack', 'babel', 'jest', 'cypress',
  'python', 'django', 'flask', 'fastapi', 'java', 'spring', 'kotlin', 'swift',
  'php', 'laravel', 'ruby', 'rails', 'go', 'rust', 'csharp', 'dot-net', 'mysql', 
  'postgresql', 'mongodb', 'redis', 'sqlite', 'firebase', 'aws', 'azure', 'gcp', 
  'docker', 'kubernetes', 'jenkins', 'github', 'gitlab', 'bitbucket', 'sass', 
  'less', 'styled-components', 'tailwindcss', 'bootstrap', 'material-ui'
];
  const scrollToContact = () => {
    const contactSection = document.getElementById('about');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="container-fluid" id="home" style={{ minHeight: '100vh', paddingTop: '70px', backgroundAttachment: 'fixed', backgroundSize: 'cover' }}>
      <Meteors number={17} />
      <div className="row align-items-center fade-in-up">
        <div className="col-md-6 my-4">
          <div className="card border-0 shadow-lg p-4" style={{ borderRadius: '20px', backgroundColor: '#F8F9FA' }}>
            <div className="card-body text-dark">
              <h1 className="card-title mb-4" style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>Web Developer | AI Enthusiast</h1>
              <p className="card-text" style={{ fontSize: '1.2rem', color: '#555' }}>
                Hi, I'm Biraj Subedi, a passionate web developer dedicated to creating exceptional digital experiences.
                With a keen eye for design and a knack for problem-solving, I transform ideas into captivating and functional websites.
              </p>
              <button type="button" className="btn btn-dark my-4" onClick={scrollToContact}>
                Explore More &rarr;
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6 d-flex justify-content-end">
          <IconCloud iconSlugs={iconSlugs} />
        </div>
      </div>
    </div>
  );
}
