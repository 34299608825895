import React from 'react';
import { FaLinkedin, FaDiscord, FaEnvelope } from 'react-icons/fa';

export default function Footer() {
  return (
    <footer className="bg-dark tw-text-white tw-py-8 tw-px-4">
      <div className="tw-container tw-mx-auto tw-flex tw-flex-col tw-md:flex-row tw-items-center tw-justify-between">
        <div className="tw-mb-4 tw-md:mb-0">
          <h2 className="tw-text-xl tw-font-bold">Biraj Subedi</h2>
          <p className="tw-text-sm tw-text-gray-400">Full-Stack Developer</p>
        </div>
        <div className="tw-flex tw-space-x-4 tw-mb-4 tw-md:mb-0">
          <a href="https://www.linkedin.com/in/subedibiraj" className="tw-text-gray-400 tw-hover:text-white">
            <FaLinkedin className="tw-h-6 tw-w-6" />
          </a>
          <a href="https://discord.com/users/masterb3771" className="tw-text-gray-400 tw-hover:text-white">
            <FaDiscord className="tw-h-6 tw-w-6" />
          </a>
          <a href="mailto:edu.biraj@gmail.com" className="tw-text-gray-400 tw-hover:text-white">
            <FaEnvelope className="tw-h-6 tw-w-6" />
          </a>
        </div>
        <div className="tw-text-gray-400 tw-text-sm">
          &copy; 2024 Biraj Subedi. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
