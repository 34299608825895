"use client";

import { useEffect, useState } from "react";

import { cn } from "@/lib/utils";

export const Meteors = ({ number = 20 }) => {
  const [meteorStyles, setMeteorStyles] = useState([]);

  useEffect(() => {
    const styles = [...new Array(number)].map(() => ({
      top: -5,
      left: Math.floor(Math.random() * window.innerWidth) + "px",
      animationDelay: Math.random() * 1 + 0.2 + "s",
      animationDuration: Math.floor(Math.random() * 8 + 2) + "s",
    }));
    setMeteorStyles(styles);
  }, [number]);

  return (
    <div className="tw-overflow-hidden tw-absolute tw-inset-0">
      {[...meteorStyles].map((style, idx) => (
        // Meteor Head
        <span
          key={idx}
          className={cn(
            "tw-pointer-events-none tw-absolute tw-left-1/2 tw-top-1/2 tw-size-0.5 tw-rotate-[215deg] tw-animate-meteor tw-rounded-full tw-bg-slate-500 tw-shadow-[0_0_0_1px_#ffffff10]",
          )}
          style={style}
        >
          {/* Meteor Tail */}
          <div className="tw-pointer-events-none tw-absolute tw-top-1/2 tw--z-10 tw-h-px tw-w-[50px] tw--translate-y-1/2 tw-bg-gradient-to-r tw-from-slate-500 tw-to-transparent" />
        </span>
      ))}
    </div>
  );
};

export default Meteors;
