import './App.css';
import Navbar from './components/Navbar';
import Body from './components/Body'
import About from './components/About';
import Portfolio from './components/Portfolio'
import Contact from './components/Contact';
import Footer from './components/Footer';
import CV from './components/CV';

function App() {
  return (
    <>
    <Navbar/>
    <Body/>
    <About/>
    <Portfolio/>
    <Contact/>
    <CV/>
    <Footer/>
    </>
  );
}

export default App;
