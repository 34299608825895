import React from 'react';
import certImg from './Responsive.jpg';
import projImg from './newsapp.jpg';

export default function Portfolio() {
  return (
    <div className="container" style={{ minHeight: '80vh', paddingTop: '70px' }}>
      <h1 className="my-4" style={{ fontSize: '2.5rem', fontWeight: 'bold' }}><strong>Portfolio</strong></h1>
      <div className="row row-cols-1 row-cols-md-2 g-4">
        <div className="col">
          <div className="card border-dark" style={{ maxWidth: '400px', transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out' }}
            onClick={() => { window.open('https://github.com/subedibiraj', '_blank'); }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.2)';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
            }}
          >
            <img src={projImg} className="card-img-top" alt="Project" />
            <div className="card-body">
              <h5 className="card-title"><strong>Projects</strong></h5>
              <p className="card-text">Explore the projects I've worked on for learning, university projects, and certifications.</p>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card border-dark" style={{ maxWidth: '400px', transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out' }}
            onClick={() => { window.open('https://www.freecodecamp.org/masterb', '_blank'); }}
            onMouseOver={(e) => {
              e.currentTarget.style.transform = 'scale(1.05)';
              e.currentTarget.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.2)';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = 'scale(1)';
              e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
            }}
          >
            <img src={certImg} className="card-img-top" alt="Certification" />
            <div className="card-body">
              <h5 className="card-title"><strong>Certifications</strong></h5>
              <p className="card-text">Explore the certifications I've earned to enhance my skills in various domains.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
