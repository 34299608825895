"use client";

import React, { useEffect, useMemo, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

export const AnimatedList = React.memo(
  ({ className, children, delay = 1000, pauseDuration = 2000 }) => {
    const [index, setIndex] = useState(0);
    const childrenArray = React.Children.toArray(children);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
      if (isPaused) return;

      const interval = setInterval(() => {
        setIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % (childrenArray.length + 1);
          if (nextIndex === childrenArray.length) {
            setIsPaused(true);
            setTimeout(() => setIsPaused(false), pauseDuration);
          }
          return nextIndex;
        });
      }, delay);

      return () => clearInterval(interval);
    }, [childrenArray.length, delay, isPaused, pauseDuration]);

    const itemsToShow = useMemo(
      () => childrenArray.slice(0, index).reverse(),
      [index, childrenArray]
    );

    return (
      <div className={`tw-flex tw-flex-col tw-items-center tw-gap-4 ${className}`}>
        <AnimatePresence>
          {itemsToShow.map((item) => (
            <AnimatedListItem key={item.key}>
              {item}
            </AnimatedListItem>
          ))}
        </AnimatePresence>
      </div>
    );
  }
);

AnimatedList.displayName = "AnimatedList";

export function AnimatedListItem({ children }) {
  const animations = {
    initial: { scale: 0, opacity: 0 },
    animate: { scale: 1, opacity: 1, originY: 0 },
    exit: { scale: 0, opacity: 0 },
    transition: { type: "spring", stiffness: 350, damping: 40 },
  };

  return (
    <motion.div {...animations} layout className="tw-w-full">
      {children}
    </motion.div>
  );
}
