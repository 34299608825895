import React from 'react';
import {
  faPython,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
  faNodeJs
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './index.scss';
import Test from '../Test';

export default function About() {
  return (
    <>
      <div className="container-fluid bg-dark" id="about" style={{ paddingTop: '70px' }}>
        <div className="row">
          <div className="col-md-6 my-4">
            <div className="card border-dark mb-3 bg-dark">
              <div className="card-body text-white">
                <h1 className="card-title my-4" style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>
                  <strong>About Me</strong>
                </h1>
                <p className="card-text" style={{ fontSize: '1rem' }}>
                  I'm a passionate web developer and machine learning enthusiast. I enjoy creating user-friendly websites and diving into the fascinating realm of artificial intelligence. With a commitment to leveraging technology for positive impact, I embark on a continuous journey of learning and creating. Let's connect and explore the possibilities together! If I were to define myself in one sentence, I'd say I'm a learner, tech-enthusiast, problem solver, and a team-player!
                </p>
                <button type="button" className="btn btn-secondary my-4" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <strong></strong>
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="stage-cube-cont">
              <div className="cubespinner">
                <div className="face1">
                  <FontAwesomeIcon icon={faNodeJs} color="#68A063" />
                </div>
                <div className="face2">
                  <FontAwesomeIcon icon={faHtml5} color="#F06529" />
                </div>
                <div className="face3">
                  <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
                </div>
                <div className="face4">
                  <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
                </div>
                <div className="face5">
                  <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
                </div>
                <div className="face6">
                  <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-dark text-white">
        <div className="row">
          <div className="col-12">
            <h1 className="mx-4"><strong>What I Have Insights About:</strong></h1>
            <div className="row my-4 mx-4" style={{ fontSize: '1.2rem' }}>
              <Test />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
