import React, { useEffect } from 'react';

/* global bootstrap */

const Toasts = () => {
  useEffect(() => {
    const liveToast = new bootstrap.Toast(document.getElementById('liveToast'));
    liveToast.show();
  }, []);

  return (
    <div className="toast-container position-fixed bottom-0 end-0 p-3">
      <div id="liveToast" className="toast fade-in" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-header">
          <img src="..." className="rounded me-2" alt=""/>
          <strong className="me-auto">Biraj Subedi</strong>
          <small>Just now</small>
          <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div className="toast-body">
          Hello, Stranger! Welcome to my Portfolio.
        </div>
      </div>
    </div>
  );
};

export default Toasts;
