import React from "react";
import Toasts from "./Toasts";
import {
  faGithub,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Navbar() {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top" >
        <div className="container-fluid">
          <a className="navbar-brand" href="#home">
            <strong>Biraj Subedi</strong>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#home">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center">
            <a
              href="https://github.com/subedibiraj"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white mx-3"
              style={{ fontSize: '1.5rem' }}
              onMouseOver={(e) => (e.currentTarget.style.color = '#f0ad4e')}
              onMouseOut={(e) => (e.currentTarget.style.color = '#fff')}
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
            <a
              href="https://www.instagram.com/__404__found__/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white mx-3"
              style={{ fontSize: '1.5rem' }}
              onMouseOver={(e) => (e.currentTarget.style.color = '#f0ad4e')}
              onMouseOut={(e) => (e.currentTarget.style.color = '#fff')}
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://www.linkedin.com/in/subedibiraj/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white mx-3"
              style={{ fontSize: '1.5rem' }}
              onMouseOver={(e) => (e.currentTarget.style.color = '#f0ad4e')}
              onMouseOut={(e) => (e.currentTarget.style.color = '#fff')}
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>
      </nav>
      <Toasts />
    </>
  );
}
