import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    const name = form.current['name'].value;
    const email = form.current['email'].value;
    const message = form.current['message'].value;

    if (!name || !email || !message) {
      alert('Please fill out all required fields.');
      return;
    }

    emailjs
      .sendForm('service_0lge5jg', 'template_i9st7nm', form.current, 'xCvs23_ZwfsVq7cBv')
      .then(
        (result) => {
          alert('Message successfully sent!');
          console.log(result.text);
          window.location.reload(false);
        },
        (error) => {
          alert('Failed to send the message, please try again');
          console.error(error.text);
        }
      );

    form.current.reset();
  };

  return (
    <div className="container-fluid bg-dark" id="contact" style={{ minHeight: '100vh', paddingTop: '70px' }}>
      <div className="row text-white justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="col-lg-6 col-md-8">
          <div className="p-4">
            <h1 className="my-4" style={{ fontSize: '2.5rem', fontWeight: 'bold' }}>
              <strong>Contact Me</strong>
            </h1>
            <p style={{ marginBottom: '20px', fontSize: '1.2rem', lineHeight: '1.6' }}>
              Let's connect and create something amazing together! Feel free to reach out.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-md-8">
          <div className="p-4">
            <form ref={form} onSubmit={sendEmail}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label" aria-required="true">
                  Name
                </label>
                <input type="text" className="form-control" id="name" name="name" maxLength="30" required />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label" aria-required="true">
                  Email
                </label>
                <input type="email" className="form-control" id="email" name="email" required />
              </div>
              <div className="mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input type="text" className="form-control" id="title" name="title" />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label" aria-required="true">
                  Message
                </label>
                <textarea className="form-control" id="message" name="message" rows="5" required></textarea>
              </div>
              <div className="mb-3">
                <button type="submit" className="btn btn-secondary btn-lg">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
