"use client";

import { cn } from "@/lib/utils";
import { AnimatedList } from "./AnimatedList";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHtml5, faCss3, faJsSquare, faBootstrap, faSass,
  faReact, faNodeJs, faGitAlt, faGithub, faNpm,
  faPython, faLinux
} from '@fortawesome/free-brands-svg-icons';
import './test.css';

let notifications = [
  { name: 'HTML5', description: 'HTML5', icon: faHtml5, color: "#F06529" },
  { name: 'CSS3', description: 'CSS3', icon: faCss3, color: "#28A4D9" },
  { name: 'Javascript', description: 'Javascript', icon: faJsSquare, color: '#EFD81D' },
  { name: 'Bootstrap', description: 'Bootstrap', icon: faBootstrap, color: '#563D7C' },
  { name: 'Sass', description: 'Sass', icon: faSass, color: '#CF649A' },
  { name: 'React', description: 'React', icon: faReact, color: '#5ED4F4' },
  { name: 'NodeJS', description: 'NodeJS', icon: faNodeJs, color: '#008080' },
  { name: 'Git', description: 'Git', icon: faGitAlt, color: '#EC4D28' },
  { name: 'Github', description: 'Github', icon: faGithub, color: 'black' },
  { name: 'Npm', description: 'Npm', icon: faNpm, color: '#CB3837' },
  { name: 'Python', description: 'Python', icon: faPython, color: '#4B8BBE' },
  { name: 'Linux', description: 'Linux', icon: faLinux, color: '#FCC624' }
];

const Notification = ({ name, description, icon, color }) => {
  return (
    <figure
      className={cn(
        "tw-relative tw-mx-auto tw-min-h-fit tw-w-full tw-max-w-[300px] tw-cursor-pointer tw-overflow-hidden tw-rounded-2xl tw-p-4",
        "tw-transition-all tw-duration-200 tw-ease-in-out tw-hover:scale-[103%]",
        "tw-bg-grey [tw-box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]",
        "tw-transform-gpu tw-dark:tw-bg-transparent tw-dark:tw-backdrop-blur-md tw-dark:[tw-border:1px_solid_rgba(255,255,255,.1)] tw-dark:[tw-box-shadow:0_-20px_80px_-20px_#ffffff1f_inset]",
      )}
    >
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-3">
        <div
          className="tw-flex tw-size-10 tw-items-center tw-justify-center tw-rounded-2xl"
          style={{ backgroundColor: color }}
        >
          <FontAwesomeIcon icon={icon} size="lg" className="tw-text-lg icon-wrapper" />
        </div>
        <div className="tw-flex tw-flex-col tw-overflow-hidden">
          <figcaption className="tw-flex tw-flex-row tw-items-center tw-whitespace-pre tw-text-lg tw-font-medium tw-dark:tw-text-white">
            <span className="tw-text-l tw-sm:tw-text-lg text">{name}</span>
            <span className="tw-mx-1"></span>
          </figcaption>
        </div>
      </div>
    </figure>
  );
};

function chunkArray(array, chunkSize) {
  const results = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    results.push(array.slice(i, i + chunkSize));
  }
  return results;
}

export default function Test({ className }) {
  const notificationChunks = chunkArray(notifications, 4);

  return (
    <div className={cn("tw-relative tw-flex tw-h-auto tw-w-full tw-flex-col tw-p-6 tw-overflow-hidden tw-rounded-lg tw-bg-background tw-md:tw-shadow-xl", className)}>
      <div className="tw-flex tw-flex-wrap">
        {notificationChunks.map((chunk, chunkIndex) => (
          <div className="tw-flex tw-flex-col tw-w-1/3 tw-p-2" key={chunkIndex}>
            <AnimatedList delay={1000} pauseDuration={10000}>
              {chunk.map((item, idx) => (
                <Notification {...item} key={idx} />
              ))}
            </AnimatedList>
          </div>
        ))}
      </div>
    </div>
  );
}
